import $ from 'jquery';
// import { cacheResponseHelper } from './helpers';
import { ARCGIS_ENDPOINT_URL } from './constants';

export const createArcGISToken = async () => {
  const username = 'provapp';
  const password = 'a59277f4d3';
  const referer = window.location.origin;
  const client = 'referer';
  const expiration = 60 * 60;
  const data = {
    username,
    password,
    client,
    referer,
    expiration,
  };

  let token = '';
  await $.ajax({
    url: `${ARCGIS_ENDPOINT_URL}/arcgis/tokens/`,
    method: 'POST',
    data,
    success: (t) => { token = t; },
    error: (error) => { console.log(error); },
  });
  return token;
};

export const placeholder = '';
