import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateCheckLists,
  getFiberYardComplaintWorkOrderDetails,
  logout,
  handleMessage,
} from '../../../../utils';
import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';

function YardComplaint({
  WorkOrderID,
  closeEditModal,
}) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [yardComplaintAddressed, setYardComplaintAddressed] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [WOComplete, setWOComplete] = useState(false);

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    const formData = {};
    formData.WorkOrderID = WorkOrderID;
    formData.AdditionalNotes = additionalNotes;
    formData.nRestorationComplete = yardComplaintAddressed ? 1 : 0;
    formData.WOComplete = WOComplete;
    updateCheckLists(sessionFromReact, formData)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  };
  useEffect(() => {
    let isMounted = true;

    getFiberYardComplaintWorkOrderDetails(sessionFromReact, WorkOrderID)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          nRestorationComplete,
          txtNotes,
          dtDateCompleted,
        } = parsed;
        setYardComplaintAddressed(nRestorationComplete === '1');
        setAdditionalNotes(txtNotes || '');
        setWOComplete(dtDateCompleted && dtDateCompleted !== '0000-00-00 00:00:00');
        disableInputs(dtDateCompleted && dtDateCompleted !== '0000-00-00 00:00:00');
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  return (
    <table className="mainContainer editWOForm">
      <tbody>
        <tr>
          <th colSpan={2} className="title"><h3 className="centerContent">Onsite Checklist - Install</h3></th>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="yardComplaintAddressed">Yard Complaint Addressed?</label>
            <div>
              <input id="yardComplaintAddressed" type="checkbox" checked={yardComplaintAddressed} onChange={() => setYardComplaintAddressed(!yardComplaintAddressed)} />
            </div>
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Additional Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setWOComplete(!WOComplete)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default YardComplaint;
