import React, { useState } from 'react';

import Asset from '../../../shared/Assets/Asset';

import styles from './styles.module.scss';

function PhysicalPlantConfig({ physicalConfig }) {
  const [showAssets, setShowAssets] = useState(false);
  const handleShowAssets = () => {
    setShowAssets(!showAssets);
  };

  const splitterBtnText = showAssets ? 'HIDE SPLITTER' : 'VIEW SPLITTER';

  if (!Object.keys(physicalConfig).length) {
    return null;
  }
  const {
    splitterPort,
    nIsFiberDropOnly,
    vcCabinetLocation,
    vcFiberSize2,
    nExistingLogicalCircuitID,
    nExistingFiberDrop,
    vcFiberStrand2,
    nONTNumber,
    vcFiberSize,
    vcFiberStrand,
    vcFiberPort,
    vcHandHoleAddress,
    vcSplitterAssetID,

  } = physicalConfig;

  let buildFiberDropOnly;
  let existingFiberCircuit;
  let existingFiberDrop;
  let fiberPort;
  if (nIsFiberDropOnly === '0') {
    buildFiberDropOnly = 'No';
  }
  if (nIsFiberDropOnly === '1') {
    buildFiberDropOnly = 'Yes';
  }
  if (nExistingLogicalCircuitID === '0') {
    existingFiberCircuit = 'No';
  }
  if (nExistingLogicalCircuitID === '1') {
    existingFiberCircuit = 'Yes';
  }
  if (nExistingFiberDrop === '0') {
    existingFiberDrop = 'No';
  }
  if (nExistingFiberDrop === '1') {
    existingFiberDrop = 'Yes';
  }
  if (vcFiberPort === '') {
    fiberPort = 'None';
  }

  return (
    <div>
      <h1 className={styles.title}>PhysicalPlantConfig</h1>
      <div className={styles.physicalPlantConfig}>
        <div className="paddingBottom10">
          <div>Build Fiber Drop Only?</div>
          <div className={styles.PPCData}>{buildFiberDropOnly}</div>
        </div>
        <div className="paddingBottom10">
          <div>Existing Fiber Circuit</div>
          <div className={styles.PPCData}>{existingFiberCircuit}</div>
        </div>
        <div className="paddingBottom10">
          <div>Splitter Pigtail Number</div>
          <span className={styles.PPCData}>{splitterPort.vcPortLabel}</span>
          <span> </span>
          <span className={styles.PPCData}>{splitterPort.vcAssetDescription}</span>
        </div>
        <div>
          <button className={styles.button} type="button" onClick={handleShowAssets}>{splitterBtnText}</button>
        </div>
        <div className="paddingBottom10">
          <div className={styles.splitterBackground}>
            {showAssets ? (
              <Asset vcAssetIdProp={vcSplitterAssetID} />
            ) : null}
          </div>
        </div>
        <div className="paddingBottom10">
          Existing Fiber Drop?
          <div className={styles.PPCData}>{existingFiberDrop}</div>
        </div>
        <div className="paddingBottom10">
          <div>Cabinet/Office</div>
          <div className={styles.PPCData}>{vcCabinetLocation}</div>
        </div>
        <div className="paddingBottom10">
          Fiber Strand 2
          <div className={styles.PPCData}>{vcFiberStrand2}</div>
        </div>
        <div className="paddingBottom10">
          Hand Hole Address
          <div className={styles.PPCData}>{vcHandHoleAddress}</div>
        </div>
        <div className="paddingBottom10">
          <div>Fiber Size 2</div>
          <div className={styles.PPCData}>{vcFiberSize2}</div>
        </div>
        {/*
          TODO: may not use it at all, confirm with Dave
            <div className="paddingBottom10">
              ONT
            </div>
            <div className="paddingBottom10">
              <button type="button">VIEW ONT</button>
            </div>
        */}
        <div className="paddingBottom10">
          ONT Number
          <div className={styles.PPCData}>{nONTNumber}</div>
        </div>
        <div className="paddingBottom10">
          Fiber Size
          <div className={styles.PPCData}>{vcFiberSize}</div>
        </div>
        <div className="paddingBottom10">
          Fiber Strand
          <div className={styles.PPCData}>{vcFiberStrand}</div>
        </div>
        <div className="paddingBottom10">
          Fiber Port
          <div className={styles.PPCData}>{fiberPort}</div>
        </div>
      </div>
    </div>
  );
}

export default PhysicalPlantConfig;
