import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getFiberDropTypesWorkOrders,
  getFiberConstructionWorkOrderDetails,
  updateCheckLists,
  logout,
  handleMessage,
} from '../../../../utils';

import { disableInputs } from '../../utils';
import { preSubmitChecker } from '../functions';

function Construction({
  WorkOrderID,
  closeEditModal,
  type,
  title = 'Construction',
  templateType = '',
}) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [dropTypes, setDropTypes] = useState([]);
  const [dropType, setDropType] = useState(0);
  const [locatesDate, setLocatesDate] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [arrivalTime, setArrivalTime] = useState('00:00');
  const [completedDate, setCompletedDate] = useState('');
  const [completedTime, setCompletedTime] = useState('00:00');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [WOComplete, setWOComplete] = useState(false);
  const renderDropType = () => {
    if (!dropTypes.length) {
      return <h1>No Droptyes available</h1>;
    }
    return dropTypes.map((dp) => <option key={`${dp.nCount}Option`} value={dp.nCount}>{dp.vcType}</option>);
  };

  const handleSubmit = () => {
    handleMessage('', 'Modal');
    const formData = {};
    formData.WorkOrderID = WorkOrderID;
    if (type !== 'business') {
      formData.nDropTypeID = dropType;
    }
    formData.LocateOnsite = locatesDate;
    formData.LocateOnsite = locatesDate;
    formData.ArrivalOnsite = `${arrivalDate} ${arrivalTime.padEnd(8, ':00')} `; // make sure it's HH:MM:SS format
    formData.CompletedDate = `${completedDate} ${completedTime.padEnd(8, ':00')} `; // make sure it's HH:MM:SS format
    formData.AdditionalNotes = additionalNotes;
    formData.WOComplete = WOComplete ? 'on' : 'off';

    updateCheckLists(sessionFromReact, formData)
      .then((response) => {
        if (response && response.toLowerCase().includes('error')) {
          handleMessage(response, 'Modal');
        } else {
          handleMessage('Updated successfully', 'Modal');
          closeEditModal();
        }
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  };
  useEffect(() => {
    let isMounted = true;
    if (type !== 'business') {
      getFiberDropTypesWorkOrders(sessionFromReact)
        .then((response) => {
          if (!isMounted) {
            return;
          }
          setDropTypes(Object.values(JSON.parse(response)));
        })
        .catch((error) => {
          // session related error
          if (error.status === 401) {
            dispatch(logout(error));
          }
        });
    }
    const formData = {
      nCount: WorkOrderID,
      type,
      templateType,
    };
    getFiberConstructionWorkOrderDetails(sessionFromReact, formData)
      .then((response) => {
        if (!response || !isMounted) {
          return;
        }
        const parsed = JSON.parse(response);
        const {
          nDropTypeID,
          dtLocateComplete,
          dtDropBuryStart,
          dtDropBuryComplete,
          txtNotes,
          vcStatus,
        } = parsed;
        const [date, time] = (dtDropBuryStart || '').split(' ');
        const [completeDate, completeTime] = (dtDropBuryComplete || '').split(' ');
        setDropType(nDropTypeID || '');
        setLocatesDate(dtLocateComplete || '');
        setArrivalDate(date || '');
        setArrivalTime(time || '00:00');
        setCompletedDate(completeDate || '');
        setCompletedTime(completeTime || '00:00');
        setAdditionalNotes(txtNotes || '');
        const completed = vcStatus === 'Complete';
        setWOComplete(completed);
        disableInputs(completed);
      })
      .catch((error) => {
        // session related error
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    return () => { isMounted = false; };
  }, [WorkOrderID]);

  return (
    <table className="editWOForm">
      <tbody>
        <tr>
          <th colSpan={2} className="title"><h3 className="centerContent">{`Onsite Checklist - ${title}`}</h3></th>
        </tr>
        {type === 'business' ? null : (
          <tr className="dataBlock">
            <th className="dataBlock">
              <p className="centerContent">
                Drop Type
              </p>
            </th>
            <td className="centerContent">
              <select value={dropType} onChange={(e) => setDropType(e.target.value)}>
                <option value={0}>Please Select One</option>
                {renderDropType()}
              </select>
            </td>
          </tr>
        )}
        <tr className="dataBlock">
          <th className="dataBlock">
            <p className="centerContent">
              Locates Date
            </p>
          </th>
          <td className="centerContent">
            <input type="date" value={locatesDate} onChange={(e) => setLocatesDate(e.target.value)} placeholder="yyyy-mm-dd" />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Drop Bury Start Date/Time</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={arrivalDate} onChange={(e) => setArrivalDate(e.target.value)} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={arrivalTime} onChange={(e) => setArrivalTime(e.target.value)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Drop Bury Complete Date/Time</p></td>
          <td className="centerContent">
            <input className="width50Percent" type="date" value={completedDate} onChange={(e) => setCompletedDate(e.target.value)} placeholder="yyyy-mm-dd" />
            <input className="width50Percent" type="time" value={completedTime} onChange={(e) => setCompletedTime(e.target.value)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="centerContent"><p>Additional Notes:</p></td>
          <td className="centerContent">
            <textarea
              className="textBlockSize"
              value={additionalNotes}
              onChange={(e) => setAdditionalNotes(e.target.value)}
            />
          </td>
        </tr>
        <tr className="checkBoxes">
          <td className="lineCheck">
            <label htmlFor="complete">Complete Work Order?</label>
            <input id="complete" type="checkbox" checked={WOComplete} onChange={() => setWOComplete(!WOComplete)} />
          </td>
        </tr>
        <tr className="dataBlock">
          <td className="update-div centerContent" colSpan={2}>
            <button type="button" onClick={() => preSubmitChecker(WOComplete, handleSubmit)}>Update Checklist</button>
          </td>
        </tr>
        <tr>
          <td>
            <div id="msgDivModal" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Construction;
