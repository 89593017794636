import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { showPusher, setPusherMsg, hidePusher } from '../../../../actions/pusherActions';

import {
  getAsset,
  getAttachmentList,
  getCircuitsFromSO,
  getPhysicalPlantConfig,
  getSalesOrderAction,
  getServiceLocationAssets,
  getWorkOrderObject,
  getWorkOrdersAvailableActions,
  getAssetEditData,
  preCacheWO,
  saveLocalStorage,
} from '../../../../utils';

import WorkOrderContext from './WorkOrderContext';
import SearchContext from '../SearchContext';

import styles from './styles.module.scss';

const calls = {
  getAsset,
  getAttachmentList,
  getCircuitsFromSO,
  getPhysicalPlantConfig,
  getSalesOrderAction,
  getServiceLocationAssets,
  getWorkOrderObject,
  getWorkOrdersAvailableActions,
  getAssetEditData,
};

function WorkOrderHome() {
  const dispatch = useDispatch();
  const { WOObj, physicalConfig, circuits } = useContext(WorkOrderContext);
  const savedWorkOrdersRaw = localStorage.getItem('savedWorkOrders');
  const savedWorkOrders = savedWorkOrdersRaw ? JSON.parse(savedWorkOrdersRaw) : [];
  const { results } = useContext(SearchContext);
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const { pathname } = useLocation();
  const WOSaved = savedWorkOrders.find((wo) => wo && wo.nCount === WOObj.WorkOrderID);

  const onlineStatus = navigator.onLine ? styles.button : styles.buttonOffline;

  useEffect(() => {
    if (!Object.keys(WOObj).length) {
      return;
    }
    const { nServiceLocationID } = WOObj;
    getServiceLocationAssets(sessionFromReact, nServiceLocationID);
  }, [WOObj]);

  if (!Object.keys(WOObj).length) {
    return null;
  }
  const hidePhysicalPlantConfigsBtn = Object.values(physicalConfig).every((v) => v === null);

  const {
    ContactMethods = {},
    Customer,
    // SalesOrderDetails,
    ServiceLocationDetails,
    WorkOrderType,
    WorkOrderDetails,
    WorkOrderAssignments,
    WorkOrder: { vcColoArea },
  } = WOObj;

  const handleDownload = () => {
    const prevSavedWorkOrders = localStorage.getItem('savedWorkOrders');
    const newSavedWorkOrders = prevSavedWorkOrders ? JSON.parse(prevSavedWorkOrders) : [];
    newSavedWorkOrders.push(results.find((ele) => ele.nCount === WOObj.WorkOrderID));
    saveLocalStorage('savedWorkOrders', newSavedWorkOrders);
    dispatch(setPusherMsg('Downloading. Please Wait. It could take ~10 seconds to fully download a work order for offline use.'));
    dispatch(showPusher());
    preCacheWO(sessionFromReact, WOObj.WorkOrderID, calls)
      .then(() => {
        dispatch(setPusherMsg('Work Order Saved'));
        dispatch(showPusher());
        setTimeout(() => {
          dispatch(hidePusher());
        }, 10000);
      });
  };

  const offlinePusher = () => {
    if (!navigator.onLine) {
      dispatch(setPusherMsg('Limited Functionality While Offline'));
      dispatch(showPusher());
      setTimeout(() => {
        dispatch(hidePusher());
      }, 10000);
    }
  };

  const contacts = Object.values(ContactMethods.contactMethodsArray || {});
  let contactStr = '';
  if (!contacts.length) {
    contactStr = 'No Contacts';
  } else if (contacts.length > 1) {
    contactStr = 'Multiple Contacts';
  } else {
    const { ContactName, Email, Phone } = contacts[0];
    contactStr = `${ContactName} - ${Email} - ${Phone}`;
  }

  return (
    <div className={`${styles.WorkOrderHome} ${styles.WOSearchResult}`}>
      <div className={styles.pageMargin}>
        <div className={styles.paddingTop10}>
          <span className="red">Last Updated: </span>
          <span className="red">{WorkOrderDetails?.dtLastUpdated}</span>
        </div>
        <div className="paddingBottom10"><h1>{Customer?.vcCustomerName}</h1></div>
        {ServiceLocationDetails ? (
          <div className="paddingBottom10">
            <span className="bold">Address:</span>
            <span>{ServiceLocationDetails.fullAddress}</span>
          </div>
        ) : null}
        <div className="paddingBottom10">
          <span>{contactStr}</span>
        </div>
        <div className="paddingBottom10">
          <span className="bold">Customer Status:</span>
          <span>{Customer?.vcCustomerStatus}</span>
        </div>
        <div className="paddingBottom10">
          <span className="bold">Colo Area:</span>
          <span>{vcColoArea}</span>
        </div>
        <div className="paddingBottom10">
          <span>{WorkOrderType?.vcDescription}</span>
        </div>
        <div className={styles.WorkOrder}>
          <div className={styles.listBorderBottom}>
            <div className="paddingBottom10">
              <Link to={`${pathname}/salesOrder`}>
                <button className={onlineStatus} type="button" onClick={offlinePusher}>Sales Order</button>
              </Link>
            </div>
            <div className="paddingBottom10">
              <div className={styles.space}>
                <Link to={`${pathname}/contacts`}>
                  <button className={styles.button} type="button" onClick={() => {}}>Contacts</button>
                </Link>
                <Link to={`${pathname}/serviceLocation`}>
                  <button className={styles.button} type="button" onClick={() => {}}>Service Location</button>
                </Link>
              </div>
            </div>
            <div className="paddingBottom10">
              <div className={styles.space}>
                <Link to={`${pathname}/arcGISMap`}>
                  <button className={onlineStatus} type="button" onClick={offlinePusher}>ArcGIS MAP</button>
                </Link>
                <Link to={`${pathname}/checkList`}>
                  <button className={styles.button} type="button" onClick={() => {}}>Checklist</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="padding">
            <div className={styles.workOrderActionsTitle}>
              <div>Work Order Action</div>
            </div>
          </div>
          <div className={styles.listBorderBottom}>
            <div className="paddingBottom10">
              {circuits.length ? (
                <Link to={`${pathname}/circuitStats`}>
                  <button className={styles.button} type="button" onClick={() => {}}>Circuit Stats</button>
                </Link>
              ) : null}
            </div>
            <div className="paddingBottom10">
              <div className={styles.space}>
                <Link to={`${pathname}/attachments`}>
                  <button className={styles.button} type="button" onClick={() => {}}>Attachments</button>
                </Link>
                <Link to={`${pathname}/viewPDF`}>
                  <button className={styles.button} type="button" onClick={() => {}}>View PDF</button>
                </Link>
              </div>
            </div>
            <div className="paddingBottom10">
              <div className={styles.space}>
                {WOSaved || !results ? <div /> : (
                  <button className={styles.button} type="button" onClick={handleDownload}>Download</button>
                )}
                <Link to={`${pathname}/photo`}>
                  <button className={styles.button} type="button" onClick={() => {}}>Upload a Photo</button>
                </Link>
              </div>
            </div>
            <div className="paddingBottom10">
              <div className={styles.space}>
                {hidePhysicalPlantConfigsBtn ? null : (
                  <div className={styles.space}>
                    <Link to={`${pathname}/physicalPlantConfig`}>
                      <button className={styles.button} type="button" onClick={() => {}}>Physical Plant Configs</button>
                    </Link>
                  </div>
                )}
                <Link to={`${pathname}/directions`}>
                  <button className={onlineStatus} type="button" onClick={offlinePusher}>Directions</button>
                </Link>
                <Link to={`${pathname}/colocations`}>
                  <button className={styles.button} type="button" onClick={() => {}}>View C.O. Map</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="padding">
            <div className={styles.workOrderActionsTitle}>
              <div>Assigned</div>
            </div>
          </div>
          <div className={styles.listBorderBottom}>
            {WorkOrderAssignments.map((assignment) => <p key={`${assignment.Name}-p`}>{assignment.Name}</p>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkOrderHome;
