import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getWorkOrderHelper,
  addWorkOrderAssemblyUnit,
  logout,
  handleMessage,
} from '../../../utils';

import '../styles.scss';

import { disableInputs } from '../utils';

function AssemblyUnits({ WOId, completed }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.session);
  const sessionFromReact = JSON.stringify(currentUser);
  const [assemblyUnitDropdownArr, setAssemblyUnitDropdownArr] = useState([]);
  const [assemblyUnitObj, setAssemblyUnitObj] = useState({}); // Existing assembly units
  const [selectedAssemblyUnit, setSelectedAssemblyUnit] = useState({});
  const [assemblyUnitQuantity, setAssemblyUnitQuantity] = useState(1); // new AU input 2
  const [assemblyDynamicInput, setAssemblyDynamicInput] = useState({
    AssetTags: [],
    SerialNumbers: [],
    BegSequential: '',
    EndSequential: '',
    ReelNum: '',
    ROWFootage: '',
  }); // optional new AU input 3

  const fetchAssemblyUnits = () => {
    getWorkOrderHelper(sessionFromReact, WOId, 'getworkorderassemblyunits')
      .then((response) => {
        setAssemblyUnitObj(JSON.parse(response));
      })
      .catch((error) => {
        handleMessage(error.statusText);
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  };

  const updateDynamicInput = (key, value, arrIdx) => {
    if (!key) {
      setAssemblyDynamicInput({});
      return;
    }
    const newAssemblyDynamicInput = { ...assemblyDynamicInput };
    if (arrIdx === undefined) {
      newAssemblyDynamicInput[key] = value;
    } else {
      newAssemblyDynamicInput[key][arrIdx] = value;
    }
    setAssemblyDynamicInput(newAssemblyDynamicInput);
  };

  const handleSubmitAssemblyUnit = () => {
    setSelectedAssemblyUnit({ nCount: '' });
    setAssemblyUnitQuantity(1);
    handleMessage('', 'Assembly');
    const formData = {
      WorkOrderID: WOId,
      AssemblyUnitID: selectedAssemblyUnit.nCount,
      QtyUnits: assemblyUnitQuantity,
      ...assemblyDynamicInput,
    };
    addWorkOrderAssemblyUnit(sessionFromReact, formData)
      .then((response) => {
        // null response means no error
        if (response === 'null') {
          fetchAssemblyUnits();
        } else {
          handleMessage(response, 'Assembly');
        }
      })
      .catch((error) => {
        handleMessage(error.statusText);
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
  };

  const renderReqSequentialInput = () => (
    <div className="inputsDiv">
      <div>
        <label htmlFor="BegSequential">
          Beg. Sequential:
          <input value={assemblyDynamicInput.BegSequential || ''} onChange={(e) => updateDynamicInput('BegSequential', e.target.value)} />
        </label>
      </div>
      <div>
        <label htmlFor="EndSequential">
          End Sequential:
          <input value={assemblyDynamicInput.EndSequential || ''} onChange={(e) => updateDynamicInput('EndSequential', e.target.value)} placeholder="Leave Blank if N/A" />
        </label>
      </div>
    </div>
  );

  const renderReqAssetTagInputs = () => {
    const array = [];
    for (let i = 0; i < assemblyUnitQuantity; i += 1) {
      assemblyDynamicInput.AssetTags = assemblyDynamicInput.AssetTags || [];
      assemblyDynamicInput.SerialNumbers = assemblyDynamicInput.SerialNumbers || [];
      if (assemblyDynamicInput.AssetTags.length < i - 1) {
        assemblyDynamicInput.AssetTags.push('');
        assemblyDynamicInput.SerialNumbers.push('');
      }
      array.push((
        <>
          <label htmlFor={`AssetTags-${i}`}>
            {`Asset Tag ${i + 1}`}
            <input id={`AssetTags-${i}`} value={assemblyDynamicInput.AssetTags[i]} onChange={(e) => updateDynamicInput('AssetTags', e.target.value, i)} />
          </label>
          <label htmlFor={`SerialNumbers-${i}`}>
            {`Serial Tag ${i + 1}`}
            <input id={`SerialNumbers-${i}`} value={assemblyDynamicInput.SerialNumbers[i]} onChange={(e) => updateDynamicInput('SerialNumbers', e.target.value, i)} placeholder="Leave Blank if N/A" />
          </label>
        </>
      ));
    }
    return array;
  };

  const renderReqAdditionalDropFeeInputs = () => (
    <div className="inputsDiv">
      <label htmlFor="ROWFootage">
        Right of Way (ROW) Footage:
        <input value={assemblyDynamicInput.ROWFootage || ''} onChange={(e) => updateDynamicInput('ROWFootage', e.target.value)} />
        ft
      </label>
    </div>
  );

  const renderReqReelNumInputs = () => (
    <div className="inputsDiv">
      <label htmlFor="ReelNum">
        Reel Num.
        <input value={assemblyDynamicInput.ReelNum || ''} onChange={(e) => updateDynamicInput('ReelNum', e.target.value)} />
      </label>
    </div>
  );

  const renderAssemblyUnitsDropdown = () => {
    if (!assemblyUnitDropdownArr.length) {
      return null;
    }
    const options = assemblyUnitDropdownArr.map((obj) => {
      const { nCount, vcCode } = obj;
      return (
        <option value={nCount} key={`${nCount}-${vcCode}Option`}>{vcCode}</option>
      );
    });

    return (
      <form className="editWOForm">
        <div className="item">
          <div className="inputsDiv">
            <label>
              Code:
              <select
                value={selectedAssemblyUnit?.nCount}
                onChange={(e) => setSelectedAssemblyUnit(assemblyUnitDropdownArr
                  .find((ele) => ele.nCount === e.target.value))}
              >
                <option value="">Please Select a Value</option>
                {options}
              </select>
            </label>
          </div>
          <div className="inputsDiv">
            <label>
              Quantity:
              <input
                value={assemblyUnitQuantity}
                onChange={(e) => setAssemblyUnitQuantity(e.target.value)}
              />
            </label>
          </div>
          {selectedAssemblyUnit?.nReqAssetTag === '1' ? renderReqAssetTagInputs() : null}
          {selectedAssemblyUnit?.nReqSequential === '1' ? renderReqSequentialInput() : null}
          {selectedAssemblyUnit?.nReqAdditionalDropFee === '1' ? renderReqAdditionalDropFeeInputs() : null}
          {selectedAssemblyUnit?.nReqReelNum === '1' ? renderReqReelNumInputs() : null}
        </div>
        <button type="button" onClick={handleSubmitAssemblyUnit}>Submit Assembly Unit</button>
      </form>
    );
  };

  useEffect(() => {
    if (!WOId) {
      return;
    }
    // get the assemtlyUnits dropdown
    getWorkOrderHelper(sessionFromReact, WOId, 'getassemblyunits')
      .then((response) => {
        const sortedArr = Object.values(JSON.parse(response))
          .sort((a, b) => a.vcCode.localeCompare(b.vcCode));
        setAssemblyUnitDropdownArr(sortedArr);
        disableInputs(completed);
      })
      .catch((error) => {
        handleMessage(error.statusText);
        if (error.status === 401) {
          dispatch(logout(error));
        }
      });
    fetchAssemblyUnits();
  }, [WOId]);

  const keys = Object.keys(assemblyUnitObj);
  let assemblyUnits;
  if (keys.length) {
    // TODO: check if this works, cms2 => workorders/templates/fiber/assemblyunits.php
    assemblyUnits = keys.map((k, i) => {
      const { Qty, DisplayString } = assemblyUnitObj[k];
      return (
        // eslint-disable-next-line react/no-danger
        <div key={`${Qty - i}-${DisplayString}-div`} className="displayString" dangerouslySetInnerHTML={{ __html: `${Qty} X ${k} <br />${DisplayString}` }} />
      );
    });
  } else {
    assemblyUnits = (
      // eslint-disable-next-line max-len
      <p>No existing assembly units found for work order. Click &apos;Add Assembly Unit&apos; to add new ones.</p>
    );
  }
  return (
    <div className="mainContainer editWOForm center">
      <h3>Assembly Units</h3>
      {renderAssemblyUnitsDropdown()}
      {assemblyUnits}
      <div id="msgDivAssembly" className="red" />
    </div>
  );
}

export default AssemblyUnits;
